import React, { useState,useEffect } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import api from '../api';

function ConsultationHistory() {
  const [email, setEmail] = useState('');
  const [orderId, setOrderId] = useState('');
  const [consultationData, setConsultationData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [cartData, setCartData] = useState([]);

  const handleFetchData = async () => {
    try {
      const response = await api.post('/re/getConsultationHistory', { email, orderId });
      setConsultationData(response.data);
      setErrorMessage(''); // Clear error message if successful
    } catch (error) {
      console.error('Error fetching consultation history:', error);
      setErrorMessage('해당 조건으로는 상담신청 내역이 없습니다! 문의 02-2051-2020');
      setConsultationData(null);
    }
  };

  const handleCancelReservation = async () => {
    const confirmReservation = window.confirm("상담신청을 취소하시겠습니까?");

    if (confirmReservation) {

    try {
      await api.post('/re/cancelReservation',  { email, orderId });
      alert('상담신청을 취소했습니다.');
      setConsultationData(null);
    } catch (error) {
      console.error('Error cancelling reservation:', error);
      alert('Failed to cancel reservation.');
    }
  }
  };


  

  // 서버로부터 데이터를 가져오는 함수
  const fetchCartData = async () => {
    try {
      const response = await api.post('/ca/selecthomeAcart', { Adivi: 15 });
      setCartData(response.data);
    } catch (error) {
      console.error('Error fetching cart data:', error);
    }
  };

  useEffect(() => {
    // 컴포넌트가 마운트될 때 데이터를 가져옴
    fetchCartData();
  }, []);


  // 예약분류 이름을 찾아서 반환하는 함수
const getReservationCategoryNames = (selectSangdam) => {
  if (!selectSangdam) return '';

  // 쉼표로 구분된 문자열일 경우 배열로 변환
  const sangdamArray = typeof selectSangdam === 'string' ? selectSangdam.split(',').map(item => item.trim()) : selectSangdam;

  // 배열일 경우
  if (Array.isArray(sangdamArray)) {
    return sangdamArray
      .map((gNum) => {
        const foundItem = cartData.find((item) => item.gNum.toString() === gNum);
        return foundItem ? foundItem.cartName : `Unknown (${gNum})`;
      })
      .join(', ');
  }

  // 단일 값일 경우
  const foundItem = cartData.find((item) => item.gNum.toString() === sangdamArray);
  return foundItem ? foundItem.cartName : `Unknown (${sangdamArray})`;
};
  return (
    <Box>
      
      <TextField
        label="이메일"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="상담접수 고유번호"
        value={orderId}
        onChange={(e) => setOrderId(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleFetchData}>
        검색
      </Button>

      {errorMessage && <Typography color="error">{errorMessage}</Typography>}

      {consultationData && (
        <Box mt={4}>
          <Typography variant="body1" className="confirmation-message">
          <Typography variant="body1">주문고유번호: {consultationData.orderId}</Typography>
          <Typography variant="body1">고객명: {consultationData.customerName}</Typography>
          <Typography variant="body1">예약일: {consultationData.reserDate}</Typography>
          <Typography variant="body1">예약시간: {consultationData.reserTime}</Typography>
          <Typography variant="body1">상담분류: {getReservationCategoryNames(consultationData.selectSangdam)}</Typography>
          <Typography variant="body1">상담금액: {consultationData.totalMoney}</Typography>
          <Typography variant="body1">입금액: {consultationData.inMoney}</Typography>
          </Typography>
          {/* 기타 상담 정보 표시 
          <Button variant="contained" color="secondary" onClick={handleCancelReservation}>
            상담취소하기
          </Button>
          */}
        </Box>
      )}
    </Box>
  );
}

export default ConsultationHistory;