import React from 'react';
import { Typography,Button } from '@mui/material';
import '../style.css'; // 스타일 파일 추가
import api from '../api';


function Confirmation({ reservationData, setReservationData, nextStep, prevStep,setStep, step }) {



 const generateOrderId = () => {
  const now = new Date();
  
  // 날짜를 기반으로 앞 6자리 생성 (YYMMDD)
  const datePart = now.getFullYear().toString().slice(2) + 
                   (now.getMonth() + 1).toString().padStart(2, '0') + 
                   now.getDate().toString().padStart(2, '0');
  
  // 나머지 4자리를 랜덤 숫자로 채움
  const randomPart = Math.floor(1000 + Math.random() * 9000);

  // 두 부분을 합쳐서 10자리 주문 코드 생성
  return `${datePart}${randomPart}`;
};


  const handleNext = async () => {
    const confirmReservation = window.confirm("신청하시겠습니까?");

    if (confirmReservation) {

    try {
      const payload = {
        orderId: generateOrderId(), // 고유한 주문 ID 생성 함수
        reserDate: reservationData.date,
        reserTime: reservationData.visityn === "방문상담" ? reservationData.time : "방문없음",
        selectSangdam: reservationData.categories.join(','), // 선택한 상담 분류
        customerName: reservationData.name,
        customerHp1: reservationData.phone,
        customerEmail: reservationData.email,
        moneyCheck: "N", // 초기값 설정
        totalMoney: reservationData.totalMoney, // 초기값 설정 (실제 금액 계산이 필요한 경우 수정)
        inMoney: 0,
        notMoney: reservationData.notMoney,
        googleCheck: "N", // 초기값 설정, 후에 구글 캘린더 이벤트 ID로 업데이트
        customerGnum: 0, // 고객 고유번호 (있다면, 필요에 따라 설정)
        adminCheck: "N", // 초기값 설정
        shoptype: reservationData.shoptype,
      };
  
      const response = await api.post('/re/insertHomeReserSMS', payload);
  
      if (response.status === 200) {
        //console.log('Reservation successfully saved:', response.data);
       
        setStep(99); // 완료 페이지로 이동
        
      } else {
        console.error('Error saving reservation:', response.data);
        alert('죄송합니다! 해당 날짜와 시간에는 예약이 불가합니다! 다른 날짜와 시간을 선택해주세요');
      }
    } catch (error) {
      console.error('Error saving reservation:', error);
      alert('죄송합니다! 해당 날짜와 시간에는 예약이 불가합니다! 다른 날짜와 시간을 선택해주세요');
    }
  }else {
    console.log("Reservation was cancelled by the user.");
  }
  };


  return (
    <div className="confirmation-container">
  <Typography variant="h6" className="confirmation-title" gutterBottom>
    최종 접수내용 확인
  </Typography>
  <Typography variant="body1" className="confirmation-message">
    <div style={{ display: 'flex' }}>
      <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>이름:</div>
      <div style={{padding:'5px' }} >{reservationData.name}님</div>
    </div>
    <div style={{ display: 'flex' }}>
      <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>상담분류:</div>
      <div style={{padding:'5px' }} >{reservationData.categoriesName}</div>
    </div>
    <div style={{ display: 'flex' }}>
      <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>방문상담여부:</div>
      <div style={{padding:'5px' }} >{reservationData.visityn}</div>
    </div>
   
    {reservationData.visityn === '방문상담' ? (
      <>
       <div style={{ display: 'flex' }}>
        <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>방문상담료:</div>
      <div style={{padding:'5px' }} >{reservationData.categoriesMoney.toLocaleString()}원

      </div>
       </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>방문상담일:</div>
          <div style={{padding:'5px' }} >{reservationData.date}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>방문상담시간:</div>
          <div style={{padding:'5px' }} >{reservationData.time}</div>
        </div>
           <div style={{padding:'5px' }} >[상담신청 완료] 버튼을 누르시면 [{reservationData.phone}]로 당사의 결제대행사인 [결제선생]의 알림톡 문자가 발송되오니, 결제해 주시면 상담예약이 완료됩니다.
      </div>

        {/*
        <div style={{ marginTop: '10px' }}>
          내용이 맞으시면 아래의 상담예약금을 저희 결제대행사인 [결제선생]을 통해 결제해 주시면 접수가 완료됩니다.
          <br />
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Button variant="contained" color="error" onClick={handleNext}>
              상담예약금 결제하기
            </Button>
          </div>
        </div>
        */}
      </>
    ) : (
      <div style={{ marginTop: '10px' }}>
        내용이 맞으시면 아래의 상담신청 버튼을 눌러서 완료시켜주시기 바랍니다.
      </div>
    )}
  </Typography>
  {step === 5 && (
    <div className="date-form-buttons">
      <Button
        variant="contained"
        onClick={() => setStep(reservationData.visityn === '방문상담' ? 4 : 3)}
      >
        전단계
      </Button>
      <Button variant="contained" color="primary" onClick={handleNext}>
        상담신청 완료
      </Button>
    </div>
  )}
</div>

    
  );
}

export default Confirmation;