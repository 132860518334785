import { createTheme } from '@mui/material';


const theme = createTheme({
  palette: {
    primary: {
     // main: '#1f883d', // 초록색
     // main: '#E7B560', // 노란색
     main: '#EEC46C', // 240911수정
    },
    secondary: {
   //   main: '#E7B560', // 대양 금색
      main:'#FDF3DB'  //노란색
    },
    error: {
      main: '#cc0000', // 빨간색
    },

    primary2: {
      //main: '#1f883d', // 초록색
      main: '#FDF3DB', // 노란색
    },

    primary3: {
      //main: '#1f883d', // 초록색
      main: '#ffbb68', // 노란색
    },

    primary4: {
      //main: '#1f883d', // 초록색
      main: '#edaa5e', // 노란색
    },

    primary5: {
      //main: '#1f883d', // 초록색
      main: '#EEC46C', // 노란색
    },

    primary6: {
      //main: '#1f883d', // 초록색
      main: '#EEC46C', // 노란색
    },

  },

  typography: {
    fontSize: 14, // 기본 글자 크기 설정
    h4: {
      fontSize: '1.5rem', // 반응형 헤더 글자 크기 설정
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
      '@media (min-width:960px)': {
        fontSize: '2.5rem',
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '120px',
          fontSize: '0.8rem',
          padding: '6px 12px',
          '@media (max-width:600px)': {
            minWidth: '90px',
            fontSize: '0.7rem',
            padding: '4px 8px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          margin: 'dense',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 'dense',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
  },
});

export default theme;