import React, { useState } from 'react';
import { Typography, RadioGroup, FormControlLabel, Radio, Button, Box } from '@mui/material';
import '../style.css'; // 스타일 파일 추가

function VisitOrNotSelection({ setReservationData, reservationData, nextStep, prevStep, setStep,step }) {
  const [visitOption, setVisitOption] = useState('');

  const handleChange = (e) => {
    setVisitOption(e.target.value);
    setStep(3);
  };

  const handleNext = () => {
    if (visitOption === 'visit') {
      nextStep(); // 방문상담예약을 선택했을 때 날짜 선택 페이지로 이동
      setReservationData((prevData) => ({
        ...prevData,
        visityn: '방문상담'
      }));

    } else if (visitOption === 'noVisit') {
      setReservationData((prevData) => ({
        ...prevData,
        visityn: '방문없음'
      }));
      setStep(5); // 방문상담없이의뢰를 선택했을 때 마지막 페이지로 이동
    } else {
      alert('옵션을 선택해주세요.');
    }
  };

  const visitClick = (sss) =>{
    setReservationData((prevData) => ({
      ...prevData,
      visityn: sss
    }));
  }

  return (
    <div className="visit-or-not-selection-container">
      <div className="title-container">
        <Typography variant="h6" className="title">상담 방법 선택</Typography>
      </div>
      <RadioGroup value={visitOption} onChange={handleChange} className="visit-option-group"  row  
      // 이 속성을 추가하여 가로로 배치
      >
        <FormControlLabel
          value="visit"
          control={<Radio />}
          label="방문상담예약"
          onClick={() => visitClick("방문상담")}
        />
        <FormControlLabel
          value="noVisit"
          control={<Radio />}
          label="방문상담없이의뢰"
          onClick={() => visitClick("방문없이의뢰")}
        />
      </RadioGroup>
      {(step === 3) && (
      <div className="visit-form-buttons">
        <Button variant="contained" onClick={prevStep}>전단계</Button>
        <Button variant="contained" color="primary" onClick={handleNext}>다음단계</Button>
      </div>
      )}
    </div>
  );
}

export default VisitOrNotSelection;
