import React, { useState, useEffect } from 'react';
import { Typography, Button, FormControlLabel, Checkbox, Box, Radio,Grid } from '@mui/material';
import '../style.css'; // CSS 파일 임포트
import api from '../api'; // Axios 인스턴스 가져오기

function CategorySelection({ reservationData, setReservationData, nextStep, prevStep,step }) {
  const [cartData, setCartData] = useState([]);

  // 서버로부터 데이터를 가져오는 함수
  const fetchCartData = async () => {
    try {
      const response = await api.post('/ca/selecthomeAcart', { Adivi: 15 });
      setCartData(response.data);
    } catch (error) {
      console.error('Error fetching cart data:', error);
    }
  };

  useEffect(() => {
    // 컴포넌트가 마운트될 때 데이터를 가져옴
    fetchCartData();
  }, []);

  // 체크박스 변경 핸들러
  const handleChangeCheck = (e) => {
    const { value, checked } = e.target;
    const categoryName = cartData.find((category) => category.gNum.toString() === value)?.cartName || '';
    const categoryMoney = cartData.find((category) => category.gNum.toString() === value)?.moneywon || 0;
    setReservationData((prevData) => {
      if (checked) {
        return {
          ...prevData,
          categories: [...prevData.categories, value],
          categoriesName: [...prevData.categoriesName, categoryName],
          categoriesMoney: [...prevData.categoriesMoney, categoryMoney],
        };
      } else {
        return {
          ...prevData,
          categories: prevData.categories.filter((category) => category !== value),
          categoriesName: prevData.categoriesName.filter((name) => name !== categoryName),
          categoriesMoney: prevData.categoriesMoney.filter((name) => name !== categoryMoney),
        };
      }
    });
  };
  

  // 라디오 버튼 변경 핸들러
const handleChange = (e) => {
  const { value } = e.target;
  const categoryName = cartData.find((category) => category.gNum.toString() === value)?.cartName || '';
   const categoryMoney = cartData.find((category) => category.gNum.toString() === value)?.moneywon || 0;
  setReservationData({
    ...reservationData,
    categories: [value],  // 선택된 하나의 카테고리만 유지
    categoriesName: [categoryName],  // 선택된 하나의 카테고리 이름만 유지
    categoriesMoney:[categoryMoney],
    totalMoney:[categoryMoney],
    notMoney:[categoryMoney],
  });
};


  // 폼 제출 핸들러
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="category-selection-container">
      <div className="category-title-container2" >
        <Typography variant="h6" className="category-title">상담분류</Typography>
        <Typography variant="body2" className="category-title" sx={{ fontSize: '0.675rem', marginLeft: '8px' }}> 상담비는 업무 수임시 수수료에서 공제됩니다</Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="category-form-row">
          {cartData.map((category) => (
           <div key={category.gNum} className="category-item">
           <FormControlLabel
             control={
               <Radio
                 name="category"
                 value={category.gNum}
                 checked={reservationData.categories.includes(category.gNum.toString())}
                 onChange={handleChange}
               />
             }
             label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" component="span" sx={{ marginRight: '5px' }}>
                      {category.cartName}
                    </Typography>
                    <Typography variant="body1" component="span" sx={{ fontSize: '0.875rem' }}>
                      [방문상담: {category.moneywon.toLocaleString()}원]
                    </Typography>
                  </Box>
             }
           />
           <Typography variant="body2" className="category-description">
             {category.cartSul}
           </Typography>
         </div>
          ))}
        </div>
        {step === 2 && ( // step이 2일 때만 버튼을 표시
          <div className="category-form-buttons">
            <Button variant="contained" onClick={prevStep}>전단계</Button>
            <Button variant="contained" color="primary" type="submit">다음단계</Button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CategorySelection;
