import React, { useState } from 'react';
import { Typography, Button, TextField, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import '../style.css'; // CSS 파일 임포트
import { useTheme } from '@mui/material/styles';  // 추가


function PersonalInfo({ reservationData, setReservationData, nextStep, step }) {
  const [agreed, setAgreed] = useState(false);
  const [expanded, setExpanded] = useState(true); // 접기 상태
  const [errors, setErrors] = useState({});


  const theme = useTheme();  // 추가된 부분: 현재 테마 가져오기
  
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const cleanedValue = value.replace(/\D/g, '');
    const formattedPhone = formatPhoneNumber(cleanedValue);
      setReservationData((prevData) => ({
        ...prevData,
        phone: formattedPhone,
      }));
      validatePhone(formattedPhone);
    } else if (name === 'email') {
      setReservationData((prevData) => ({
        ...prevData,
        email: value,
      }));
      validateEmail(value);
    } else {
      setReservationData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

 const formatPhoneNumber = (phoneNumber) => {
  // 010-1234-5678 형태로 변환
  const match = phoneNumber.match(/^(\d{3})(\d{3,4})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return phoneNumber;
};

  const validatePhone = (phone) => {
    const phoneRegex = /^010-\d{3,4}-\d{4}$/;
    if (!phoneRegex.test(phone)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: '01012345678 숫자만 입력해주세요.',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: null,
      }));
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: '올바른 이메일 형식으로 입력해주세요.',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: null,
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    setAgreed(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // 마지막으로 유효성 검사
    validatePhone(reservationData.phone);
    validateEmail(reservationData.email);

    if (agreed && !errors.phone && !errors.email) {
      nextStep();
    } else {
      alert('모든 항목을 넣어주시고, 개인정보보호정책에 동의하셔야 합니다.');
    }
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="personal-info-container">
      <div className="title-container">
        <Typography variant="h6" className="title">기본정보</Typography>
      
      </div>
      {expanded && (
        <>
          <div className="policy-container">
            <Typography variant="body1" className="policy-content">
            개인정보취급방침은 고객의 개인정보 보호를 위하여 글로벌세무그룹대양이 실시하는 개인정보 수집의 목적과 그 정보의 정책적, 시스템적 보안에 관한 규정입니다. 이는 고객의 기본적인 사생활 비밀과 자유 및 통신 비밀을 보장하고 불법적인 도청 정보 유출로 인한 인권피해가 발생하지 않도록 하고자 마련된 것입니다.
<br /><br />
제1항(개인정보 수집 범위 및 이용 목적)<br />
1. 글로벌세무그룹대양은 당사의 고객에게 제공하는 세무상담을 이용하기 위해 필요한 최소한의 정보를 필수로 수집하고 있습니다.<br />
2. 글로벌세무그룹대양이 비회원을 대상으로 제공받는 회원정보는 다음과 같습니다.<br />
① 고객명 : 상담시 고객확인용<br />
② 휴대폰 : 상담에 관한 내용에 대한 개인식별 및 전화응대용<br />
③ 이메일 : 상담후 상담에 대한 결과에 대한 메일발송 및 개인식별용<br />
④ IP Address : 불량회원의 부정 이용 방지와 비인가 사용 방지<br /><br />

글로벌세무그룹대양은 비회원으로 상담신청하시는 모든 고객의 개인정보를 위에서 밝힌 목적 이외에는 절대로 사용할 수 없으나, 회원 개인정보의 사용 목적과 용도가 변경될 경우에는 반드시 모든 고객님께 동의를 구할 것입니다.<br />
제2항(개인정보 보유기간 및 이용기간)<br />
1. 글로벌세무그룹대양에서 제공하는 서비스를 받는 동안 비회원님의 개인 정보는 글로벌세무그룹대양에서 계속 보유하며 서비스 제공을 위해 이용하게 됩니다. 고객이 삭제의사를 밝히더라도 아래의 정보는 즉시 파괴되지 않고, 유지됩니다.<br />
① 보존 항목 : 상담자의 이름 및 상담 고유번호<br />
② 보존 근거 : 서비스 이용의 혼선 방지 및 "몰" 의 상담내용에 대한 결제관련 정보 확인<br />
③ 보존 기간 : 최장 5년<br />
2. 회사는 귀중한 고객님의 개인정보를 안전하게 처리하며, 유출의 방지를 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다.<br />
① 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br />
② 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br />
            </Typography>
          </div>
          <div className="checkbox-container">
            <FormControlLabel
              control={<Checkbox checked={agreed} onChange={handleCheckboxChange}  sx={{
                '&.Mui-checked': {
                  color: theme.palette.primary5.main, // 체크된 상태의 색상
                },
              }}/>}
              label="개인정보보호정책에 동의합니다"
             
                       />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <TextField
                label="고객명"
                name="name"
                value={reservationData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
                className="form-field"
                autoComplete="off"
      inputProps={{ lang: 'ko' }}
       color="primary3"
              />
              <TextField
                label="핸드폰번호"
                name="phone"
                value={reservationData.phone}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
                className="form-field"
                error={!!errors.phone}
                helperText={errors.phone}
                color="primary3"
              />
              <TextField
                label="이메일"
                name="email"
                value={reservationData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
                className="form-field"
                error={!!errors.email}
                helperText={errors.email}
                color="primary3"
               // autoComplete="email" // 자동 완성 힌트 설정
  inputProps={{ inputMode: 'email', pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$' }} // 영어 입력 유도
              />
            </div>
           {step === 1 && ( // step이 1일 때만 버튼을 표시
              <div className="form-buttons">
                <Button variant="contained" color="primary" type="submit">다음단계</Button>
              </div>
            )}
          </form>
        </>
      )}
    </div>
  );
}

export default PersonalInfo;
