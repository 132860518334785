// src/Login.js
import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../features/login/loginSlice';



const Login = () => {
    const [ms_id, setMsId] = useState('');
    const [ms_pass, setMsPass] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const handleLogin = async (e) => {
      e.preventDefault();
      setError('');
  
      try {
        const response = await axios.post('http://localhost:3009/api/mb/login', { ms_id, ms_pass });
        const { oaccessToken, orefreshToken } = response.data;
  
        // 토큰을 로컬 스토리지에 저장
        localStorage.setItem('access_token', oaccessToken);
        localStorage.setItem('refresh_token', orefreshToken);
        localStorage.setItem('msId', ms_id);  //성공한것이기에 내가 넣은 아이디로 스토리지에 쓴다.
  
        // 로그인 성공 후 상태 업데이트 및 홈페이지로 리디렉션
        dispatch(login());
        navigate('/');
      } catch (err) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('msId');
        setError('로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.');
        setMsPass('');
      }
    };
  
    return (
      <Container component="main" maxWidth="xs">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
        >
          <Typography component="h1" variant="h5">
            로그인
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="ms_id"
              label="아이디"
              name="ms_id"
              autoComplete="ms_id"
              autoFocus
              value={ms_id}
              onChange={(e) => setMsId(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="ms_pass"
              label="비밀번호"
              type="password"
              id="ms_pass"
              autoComplete="current-password"
              value={ms_pass}
              onChange={(e) => setMsPass(e.target.value)}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ margin: '24px 0 16px' }}
            >
              로그인
            </Button>
          </form>
        </Box>
      </Container>
    );
  };
  
  export default Login;