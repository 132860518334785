import React, { useState } from 'react';
import { Typography, Box, Paper, Tabs, Tab } from '@mui/material';
import PersonalInfo from './PersonalInfo';
import CategorySelection from './CategorySelection';
import DateSelection from './DateSelection';
import Confirmation from './Confirmation';
import VisitOrNotSelection from './VisitOrNotSelection';
import ConsultationHistory from './ConsultationHistory';
import CompleteForm from './completeForm'; // 완료 페이지 컴포넌트 임포트
import '../style.css';
import logo from '../assets/daeyang_logo01.png'; // 로고 이미지 경로를 import


function About() {
  const [tabIndex, setTabIndex] = useState(0); 
  const [step, setStep] = useState(1);
  const [reservationData, setReservationData] = useState({
    name: '',
    phone: '',
    email: '',
    ju1: '',
    ju2: '',
    categories: [],
    categoriesName: [],
    categoriesMoney:0,
    date: '',
    time: '',
    visityn:'',
    totalMoney:0,
    inMoney:0,
    notMoney:0,
    shoptype:1
  });

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const nextStep = () => {
    if (step < 5) {
      setStep(prevStep => prevStep + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(prevStep => prevStep - 1);
    }
  };

  return (
    <div className="form-container">
      {/* 로고 이미지 추가 */}
      <Box className="logo-container" sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
        <img src={logo} alt="Logo" />
      </Box>


      <Tabs 
        value={tabIndex} 
        onChange={handleTabChange} 
        aria-label="about-tabs"
        sx={{
          '& .MuiTab-root': {
            flex: 1, // 각 탭이 화면의 절반을 차지하도록 설정
            maxWidth: '50%',
            fontSize: '1rem',
            color: '#000', // 기본 글자 색상 설정
          },
          '& .MuiTabs-indicator': {
            display: 'none', // 기본 밑줄 제거
          },
          '& .Mui-selected': {
            backgroundColor: '#EEC46C', // 선택된 탭 배경색 설정
            color: '#', // 선택된 탭 글자 색상 설정
          },
          '& .MuiTab-root.Mui-selected': {
            color: '#000', // 선택된 탭 글자 색상 설정
          },
        }}
      >
        <Tab label="상담 신청 작성" />
        <Tab label="신청내역 확인" />
      </Tabs>
      
      {tabIndex === 0 && (
        <>
          <Paper elevation={3} className="welcome-container">
            <Typography variant="body1" gutterBottom>
              저희 글로벌세무그룹 대양을 찾아주셔서 감사합니다.
            </Typography>
            <Typography variant="body1" gutterBottom>
              고객님의 상담예약은 아래의 정보를 입력해주시면 접수됩니다.
            </Typography>
            <Typography variant="body1">
              감사합니다.(문의 02-2051-2020)
            </Typography>
          </Paper>

          {step === 99 ? (
            <CompleteForm setStep={setStep} reservationData={reservationData} setReservationData={setReservationData}/>
          ) : (
            <>
              <PersonalInfo
                reservationData={reservationData}
                setReservationData={setReservationData}
                nextStep={nextStep}
                step={step}  // step 상태 전달
              />

              {step >= 2 && (
                <CategorySelection
                  reservationData={reservationData}
                  setReservationData={setReservationData}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  step={step}  // step 상태 전달
                />
              )}

              {step >= 3 && (
                <VisitOrNotSelection
                  setReservationData={setReservationData}
                  reservationData={reservationData}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  setStep={setStep}
                  step={step}
                />
              )}

              {step >= 4 && reservationData.visityn === '방문상담' && (
                <DateSelection
                  reservationData={reservationData}
                  setReservationData={setReservationData}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  step={step}
                />
              )}

              {step === 5 && (
                <Confirmation 
                  reservationData={reservationData}
                  setReservationData={setReservationData}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  setStep={setStep}
                  step={step}
                />
              )}
            </>
          )}
        </>
      )}

      {tabIndex === 1 && (
        <>
          <Paper elevation={3} className="welcome-container">
            <Typography variant="body1" gutterBottom>
              신청하셨던 메일로 보내드린 상담고유번호와 이메일 주소를 넣어주세요!
            </Typography>
            <Typography variant="body1">
              감사합니다.(문의 02-2051-2020)
            </Typography>
          </Paper>
          <ConsultationHistory />
        </>
      )}
    </div>
  );
}

export default About;
