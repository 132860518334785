import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, 
  Box, Select,MenuItem,FormControl,InputLabel,TextField,Checkbox,FormControlLabel  } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import '../style.css'; // 스타일 파일 추가

const TimeSlots = ({ date, events, onTimeSlotClick }) => {
  const timeSlots = [];
  for (let hour = 9; hour <= 18; hour++) {
    timeSlots.push(`${hour}:00`);
  }

  const isTimeSlotReserved = (timeSlot) => {
    const [hour] = timeSlot.split(':');
    const slotStart = dayjs(date).hour(hour).minute(0).second(0).toDate();
    const slotEnd = dayjs(date).hour(hour).minute(59).second(59).toDate();

    return events.some(event => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      return (slotStart >= eventStart && slotStart <= eventEnd) || (slotEnd >= eventStart && slotEnd <= eventEnd);
    });
  };

  const isTimeSlotPast = (timeSlot) => {
    const [hour] = timeSlot.split(':');
    const slotTime = dayjs(date).hour(hour).minute(0).second(0);
    return slotTime.isBefore(dayjs());
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      {timeSlots.map((time) => (
        <Grid item key={time}>
          <Button
            variant="contained"
            onClick={() => onTimeSlotClick(time)}
            disabled={isTimeSlotReserved(time) || isTimeSlotPast(time)}
          >
            {time}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};



const ReservationForm = ({ open, handleClose, events,filterCartDataByDiviNum,data,handleSave,handleDelete }) => {

  const initialDate = data && data.reserDate ? dayjs(data.reserDate) : dayjs();
  const initialTime = data && data.reserTime ? data.reserTime : null;

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedTime, setSelectedTime] = useState(initialTime);
  const [formData, setFormData] = useState({
    ...data,
    reserDate: initialDate.format('YYYY-MM-DD'),
    reserTime: initialTime || '',
    selectSangdam: data?.selectSangdam || [] // Initialize with an empty array for multiple selections
  });

  const jinState = filterCartDataByDiviNum(15); // 홈피 선택사항
  
  console.log(data);


  /*
  useEffect(() => {
    if (selectedDate) {
      setFormData((prevData) => ({
        ...prevData,
        reserDate: dayjs(selectedDate).format('YYYY-MM-DD')
      }));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedTime) {
      setFormData((prevData) => ({
        ...prevData,
        reserTime: selectedTime
      }));
    }
  }, [selectedTime]);
   */

  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
        reserDate: dayjs(data.reserDate).format('YYYY-MM-DD'),
        reserTime: data.reserTime ? data.reserTime : '',
        selectSangdam: data.selectSangdam || [],
        customerName: data.customerName || '',
        customerHp1: data.customerHp1 || '',
        customerEmail: data.customerEmail || '',
      });
      setSelectedDate(dayjs(data.reserDate));
      setSelectedTime(data.reserTime ? data.reserTime : '');
    }
  }, [data]);

  //console.log("data : " + formData.selectSangdam);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => {
      const updatedSelectSangdam = checked
        ? [...prevData.selectSangdam, parseInt(name)]
        : prevData.selectSangdam.filter((item) => item !== parseInt(name));
      return { ...prevData, selectSangdam: updatedSelectSangdam };
    });
  };

  
   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleTimeSlotClick = (time) => {
    setFormData((prevData) => ({
      ...prevData,
      reserTime: time
    }));
  };

  const shouldDisableDate = (date) => {
    return dayjs(date).isBefore(dayjs().startOf('day'));
  };


  const handleFormSave = () => {
    handleSave(formData);
  };

  const handleMonthChange = (newMonth) => {
    setSelectedDate(dayjs(newMonth));
  };

  const handleFormDel = () => {
    handleDelete(formData);
  };
    
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>예약하기</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
            defaultValue={dayjs(initialDate.format('YYYY-MM-DD'))}
            onChange={(newValue) => setFormData((prevData) => ({
              ...prevData,
              reserDate: dayjs(newValue).format('YYYY-MM-DD')
            }))}
            //onMonthChange={handleMonthChange}
            shouldDisableDate={shouldDisableDate}
            className="custom-datepicker" // 커스텀 클래스 추가
          />
        </LocalizationProvider>
        {formData.reserDate && (
          <TimeSlots date={formData.reserDate} events={events} onTimeSlotClick={handleTimeSlotClick} />
        )}
        {formData.reserTime && (
          <Box sx={{ mt: 2 }}>
           <Typography variant="h6">예약 정보</Typography>
            <Typography>선택한 날짜: {formData.reserDate}</Typography>
            <Typography>선택한 시간: {formData.reserTime}</Typography>
            {/* 여기에 예약 폼 요소 추가 */}
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">서비스 선택</Typography>
              {jinState.map((code) => (
                <FormControlLabel
                  key={code.gNum}
                  control={
                   <Checkbox
                      checked={formData.selectSangdam.includes(code.gNum)}
                      onChange={handleCheckboxChange}
                      name={code.gNum.toString()}
                    />
                  }
                  label={code.cartName}
                />
              ))}
            </Box>

            <TextField
              margin="dense"
              name="customerName"
              label="고객명"
              type="text"
              fullWidth
              value={formData.customerName || ''}
              onChange={handleChange}
            />
            
            <TextField
              margin="dense"
              name="customerHp1"
              label="핸드폰"
              type="text"
              fullWidth
              value={formData.customerHp1 || ''}
              onChange={handleChange}
            />

            <TextField
              margin="dense"
              name="customerEmail"
              label="이메일"
              type="text"
              fullWidth
              value={formData.customerEmail || ''}
              onChange={handleChange}
            />

          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleFormDel} color="primary">
          삭제
        </Button>

        <Button onClick={handleClose} color="primary">
          취소
        </Button>
        <Button onClick={handleFormSave} color="primary">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ReservationForm;