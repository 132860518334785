import React, { useState, useEffect } from 'react';
import api from '../api'; // Axios 인스턴스 가져오기
import '../style.css'; // CSS 파일 임포트
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReservationForm from './reservationForm';


const localizer = momentLocalizer(moment);


const Calendargoogle = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [reservationDialogOpen, setReservationDialogOpen] = useState(false);
    const [cartData, setCartData] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);


    const handleAuth = async () => {
    try {
      const response = await api.post('/au/auth');
      const data = await response.data;
      if (data.url) {
        window.location.href = data.url;  // 인증 URL로 리디렉션
      } else {
        console.error('No URL found in response');
      }
    } catch (error) {
      console.error('Error fetching auth URL:', error);
    }
  };


  const gotoCarendal= async () => {
    try {
      const response = await api.post('/au/getCalendarList');
      const data = await response.data;
      if (data.url) {
        window.location.href = data.url;  // 인증 URL로 리디렉션
      } else {
        console.error('No URL found in response');
      }
      setReservationDialogOpen(true);
    } catch (error) {
      console.error('Error fetching auth URL:', error);
    }

  };

  const handleCallback = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      try {
        //const response = await axios.get(`http://localhost:3000/oauth2callback?code=${code}`);
         const response = await api.post('/au/oauth2callback',code);
        const data = await response.data;
      if (data.url) {
        window.location.href = data.url;  // 인증 URL로 리디렉션
      } else {
        console.error('No URL found in response');
      }
      } catch (error) {
        console.error('Error retrieving tokens:', error);
      }
    }
  };


   const fetchCartData = async () => {
    try {
      const response = await api.post('/ca/selectallAcart', { Adivi: 0 });
      setCartData(response.data);
    } catch (error) {
      console.error('Error fetching cart data:', error);
    }
  };



   React.useEffect(() => {
    handleCallback();
    fetchCartData();
  }, []);


  const filterCartDataByDiviNum = (diviNum) => {
    return cartData.filter(cart => cart.diviNum === diviNum).sort((a, b) => a.sunWe - b.sunWe);
  };



   const fetchEvents = async (start, end) => {
    try {
      const response = await api.post('/au/events',{
        calendarId: 'c_477jf3vmd9bbin5upv925ed93g@group.calendar.google.com',  // 여기에 원하는 캘린더 ID를 입력
        start: start.toISOString(),
        end: end.toISOString()
      });
      const googleEvents = response.data;
      const formattedEvents = googleEvents.map(event => ({
        id: event.id, // 여기에서 이벤트 ID를 포함합니다.
        title: event.summary,
        start: new Date(event.start.dateTime || event.start.date),
        end: new Date(event.end.dateTime || event.end.date)
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching events', error);
    }
  };

  useEffect(() => {
    // 초기 날짜 범위 설정 (예: 현재 월)
    const start = moment().startOf('month').toDate();
    const end = moment().endOf('month').toDate();
    fetchEvents(start, end);
  }, []);



     const handleRangeChange = (range) => {
    const start = range.start;
    const end = range.end;
    fetchEvents(start, end);
    };

    const fetchData = async (filters = {}) => {
        setLoading(true);
        try {
          const response = await api.post('/au/getCalendarList', filters);
          const rowsWithId = response.data.map((row, index) => ({ id: row.gNum || index, ...row }));
          setData(rowsWithId);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };


      useEffect(() => {
        fetchData();
      }, []);


   const handleReservationFormClose = () => {
    setReservationDialogOpen(false);
    setSelectedEvent(null);
  };


   const handleEventSelect = async (event) => {
     try {
    // 백엔드에 이벤트 세부 정보를 요청합니다.
    //console.log(event);
console.log(event); // 이벤트 객체의 구조 확인
    // 현지 시간대로 변환
    const start = new Date(event.start).toLocaleString('sv-SE', { timeZone: 'Asia/Seoul' });
    const end = new Date(event.end).toLocaleString('sv-SE', { timeZone: 'Asia/Seoul' });
    const title = event.title.includes(':') ? event.title.split(':')[1].trim() : event.title;
    const googleEventId = event.id || event.googleEventId; // 이벤트 객체에서 ID 가져오기


   console.log('Google Event ID:', googleEventId); // ID 확인
    
    const response = await api.post('/re/getEventDetails', {
      googleEventId: googleEventId,
     
    });
    
    const eventData = response.data;
    // 받아온 이벤트 세부 정보를 ReservationForm으로 넘깁니다.
    setReservationDialogOpen(true);
     setSelectedEvent({ ...eventData, googleEventId }); // 이벤트 ID 포함
  } catch (error) {
    console.error('Error fetching event details:', error);
  }

  };



  const handleSave = async (updatedData) => {
    try {
      const date = moment(updatedData.reserDate); // updatedData.reserDate를 moment 객체로 변환
     const startOfLastMonth = date.subtract(1, 'month').startOf('month').toDate(); // 이전 달의 시작일
    const endOfNextMonth = date.add(2, 'month').endOf('month').toDate(); // 다음 달의 종료일

  

      if (updatedData.gNum) {
        // 기존 데이터 수정
        const response = await api.post('/re/updateReser', updatedData);
        if (response.status === 200) {
          fetchEvents(startOfLastMonth, endOfNextMonth);
          setReservationDialogOpen(false);
        }
       } else {
        // 신규 데이터 입력
        const response = await api.post('/re/insertReser', updatedData);
        if (response.status === 200) {
          fetchEvents(startOfLastMonth, endOfNextMonth);
          setReservationDialogOpen(false);
        }
      }
    } catch (error) {
      console.error('Error saving reservation:', error);
    }
  };

  const handleDelete = async (updatedData) => {
    try {
        const date = moment(updatedData.reserDate); // updatedData.reserDate를 moment 객체로 변환
     const startOfLastMonth = date.subtract(1, 'month').startOf('month').toDate(); // 이전 달의 시작일
    const endOfNextMonth = date.add(2, 'month').endOf('month').toDate(); // 다음 달의 종료일

    
      const response = await api.post('/re/deleteReser', updatedData);
      fetchEvents(startOfLastMonth, endOfNextMonth);
      setReservationDialogOpen(false);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
      

  return (
    <div>
      <h1>일정관리</h1>
       <button onClick={handleAuth}>구글계정연동</button>
       <button onClick={gotoCarendal}>일정예약폼</button>
        <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
         onRangeChange={handleRangeChange}  // 달력 범위 변경 시 이벤트 가져오기
         onSelectEvent={handleEventSelect}
      />
        <ReservationForm
         open={reservationDialogOpen}
        handleClose={handleReservationFormClose}
        events={events} // 구글 캘린더 이벤트 전달
        filterCartDataByDiviNum={filterCartDataByDiviNum}
        handleSave={handleSave}
        handleDelete={handleDelete}
        data={selectedEvent}
      />

    </div>
  );
};

export default Calendargoogle;

