import React from 'react';
import { Typography,Button } from '@mui/material';


function CompleteForm({ setStep,reservationData,setReservationData }) {
  const handleNext =() => {
     setReservationData({
          name: '',
          phone: '',
          email: '',
          ju1: '',
          ju2: '',
          categories: [],
          categoriesName: [],
          date: '',
          time: '',
          visityn:'',
        });

    setStep(1); // 완료 페이지로 이동
  }
  return (
    <div>
     <Typography variant="h6" className="confirmation-title" gutterBottom>
    상담신청완료
  </Typography>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>

        <Typography variant="body1" className="confirmation-message">
    <div style={{ display: 'flex' }}>
      <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>이름:</div>
      <div style={{padding:'5px' }} >{reservationData.name}님</div>
    </div>
    <div style={{ display: 'flex' }}>
      <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>상담분류:</div>
      <div style={{padding:'5px' }} >{reservationData.categoriesName.join(', ')}</div>
    </div>
    <div style={{ display: 'flex' }}>
      <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>방문상담여부:</div>
      <div style={{padding:'5px' }} >{reservationData.visityn}</div>
    </div>
    {reservationData.visityn === '방문상담' ? (
      <>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>방문상담일:</div>
          <div style={{padding:'5px' }} >{reservationData.date}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '120px', fontWeight: 'bold',padding:'5px' }}>방문상담시간:</div>
          <div style={{padding:'5px' }} >{reservationData.time}</div>
        </div>
        <div style={{ marginTop: '10px' }}>
        위와 같이 방문상담이 예약되었습니다! 접수번호는 메일로 보내드렸습니다. 감사합니다.
      </div>
        
      </>
    ) : (
      <div style={{ marginTop: '10px' }}>
        고객님께서 궁금해하시는 분류에 대해서 메일로 보내드리오니, 메일을 참고하시기 바랍니다
      </div>
    )}
  </Typography>

              <Button variant="contained" color="primary3" onClick={handleNext} >처음화면</Button>
            </div>

    </div>
  );
}

export default CompleteForm;

