import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Checkbox, FormControlLabel, IconButton,Grid,Box } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import '../style.css'; // 스타일 파일 추가
import api from '../api'; // Axios 인스턴스 가져오기
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



const TimeSlots = ({ date, events, onTimeSlotClick,nowtime }) => {
  const timeSlots = [];
  
  // 첫 시간 슬롯을 9:30으로 설정
  timeSlots.push('9:30');
  timeSlots.push('10:00');
  timeSlots.push('10:30');
  timeSlots.push('11:00');
  timeSlots.push('11:30');
  timeSlots.push('12:00');
  timeSlots.push('13:30');
  timeSlots.push('14:00');
  timeSlots.push('14:30');
  timeSlots.push('15:00');
  timeSlots.push('15:30');
  timeSlots.push('16:00');
  timeSlots.push('16:30');
  timeSlots.push('17:00');
  timeSlots.push('17:30');
 
  const isTimeSlotReserved = (timeSlot) => {
    const [hour, minute] = timeSlot.split(':');
    const slotStart = dayjs(date).hour(hour).minute(minute).second(0).toDate();
    const slotEnd = dayjs(slotStart).add(29, 'minute').add(59, 'second').toDate();

    return events.some(event => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      return (slotStart >= eventStart && slotStart <= eventEnd) || (slotEnd >= eventStart && slotEnd <= eventEnd);
    });
  };

  const isTimeSlotPast = (timeSlot) => {
    const [hour, minute] = timeSlot.split(':');
    const slotTime = dayjs(date).hour(hour).minute(minute).second(0);
    return slotTime.isBefore(dayjs(nowtime));
  };

  return (
    <Grid container spacing={1} className="time-slot-grid">
      {timeSlots.map((time) => (
        <Grid item key={time} className="time-slot-button">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onTimeSlotClick(time)}
            disabled={isTimeSlotReserved(time) || isTimeSlotPast(time)}
          >
            {time}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};


const DateSelection = ({ reservationData, setReservationData, nextStep, prevStep, step }) => {
  const [events, setEvents] = useState([]);
   const [expanded, setExpanded] = useState(true); // 접기 상태
   const [selectedDate, setSelectedDate] = useState(dayjs());
   const [selectedTime, setSelectedTime] = useState('');
   const [serverTime, setServerTime] = useState(null); // 서버 시간을 저장할 상태
  const fetchEvents = async (start, end) => {
    try {
      const response = await api.post('/au/homeevent2', {  //구글 달력에서 직접 가지고 올때
      //const response = await api.post('/re/getEvents', {   //DB에서만 가져올때
        calendarId: 'c_477jf3vmd9bbin5upv925ed93g@group.calendar.google.com',
        start: start.toISOString(),
        end: end.toISOString()
      });
      const googleEvents = response.data;
     // console.log(googleEvents);
      const formattedEvents = googleEvents.map(event => ({
        //구글에서 직접 가지고올때
         id: event.id,
        title: event.summary,
        start: new Date(event.start.dateTime || event.start.date),
        end: new Date(event.end.dateTime || event.end.date)

       //DB에서만 가지고 올때
      //  id: event.gNum,  // 이벤트 고유 ID를 id로 설정
      //  title: event.title || event.orderId,  // title이 없으면 orderId를 사용
       // start: new Date(event.start.replace('.000Z', '')),
      //  end: new Date(event.end.replace('.000Z', ''))


      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching events', error);
    }
  };



  useEffect(() => {
    //const start = moment().startOf('month').toDate();
    //const end = moment().add(2, 'months').endOf('month').toDate(); // 두 달 후의 마지막 날
    //fetchEvents(start, end);

    // setReservationData((prevData) => ({
    //  ...prevData,
    //   date: dayjs().format('YYYY-MM-DD'),
    //}));
   
    const fetchServerTime = async () => {
      try {
        const response = await api.post('/re/nowtime'); // 서버 엔드포인트로 요청
        const serverDate = new Date(response.data.serverTime); // 서버에서 받은 시간
        setServerTime(serverDate); // 서버 시간 상태로 저장

        // 서버 시간을 기반으로 이벤트 데이터 가져오기
        const start = dayjs(serverDate).startOf('month').toDate();
        const end = dayjs(serverDate).add(2, 'months').endOf('month').toDate();
        fetchEvents(start, end);
        setSelectedDate(dayjs(serverDate));  // 서버 날짜를 사용
        setReservationData((prevData) => ({
          ...prevData,
          date: dayjs(serverDate).format('YYYY-MM-DD'), // 서버 날짜를 사용
        }));
      } catch (error) {
        console.error('Failed to fetch server time:', error);
      }
    };

    fetchServerTime();


  }, []);



  useEffect(() => {
    if (reservationData.date) {
      setSelectedDate(dayjs(reservationData.date));
      
    }
    if (reservationData.time) {
      setSelectedTime(reservationData.time);
    }
  }, [reservationData]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setReservationData((prevData) => ({
      ...prevData,
      date: newDate.format('YYYY-MM-DD')
    }));
  };

  const handleTimeSlotClick = (time) => {
    setSelectedTime(time);
    setReservationData((prevData) => ({
      ...prevData,
      time
    }));
  };

  const handleNext = () => {
    if (!selectedDate || !selectedTime) {
      alert('날짜와 시간을 선택해주세요.');
      return;
    }
    nextStep();
  };


   const toggleExpand = () => {
    setExpanded(!expanded);
  };


  const handleMonthChange = (newMonth) => {
    setSelectedDate(newMonth);
  };

  const handlePrevMonth = () => {
    setSelectedDate(selectedDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedDate(selectedDate.add(1, 'month'));
  };

  return (
    <div className="date-selection-container">
      <div className="title-container">
        <Typography variant="h6" className="title">일정선택</Typography>
        <IconButton onClick={toggleExpand}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>
       {expanded && (
        <div className="date-time-selection">
          <div className="calendar-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <IconButton onClick={handlePrevMonth}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h5" align="center" gutterBottom>
          {selectedDate.format('YYYY-MM')}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
             <StaticDatePicker
  displayStaticWrapperAs="desktop"
  openTo="day"
  value={selectedDate}
  onChange={handleDateChange}
  onMonthChange={handleMonthChange}
  shouldDisableDate={(date) => {
    const today = dayjs(serverTime).startOf('day');
    const threeWeeksFromToday = today.add(3, 'week');

    // 날짜가 오늘보다 이전이거나 3주 후보다 크거나, 또는 주말이면 비활성화
    return date.isBefore(today) || date.isAfter(threeWeeksFromToday) || date.day() === 0 || date.day() === 6;
  }}
  renderInput={(params) => <TextField {...params} />}
  sx={{
    '.MuiPickersToolbar-root': {
      display: 'none',
    },
    '.MuiPickersCalendarHeader-labelContainer': {
      display: 'none', // 기존의 월/연도 텍스트 숨기기
    },
    '.MuiPickersCalendarHeader-root': {
            display: 'none', // 기존의 월/연도 전환 버튼 숨기기
          },
  }}
/>

            </LocalizationProvider>
          </div>
          <div className="time-slots-container">
           
            {selectedDate && (
              <TimeSlots date={selectedDate} events={events} onTimeSlotClick={handleTimeSlotClick} nowtime={serverTime} />
            )}
             {selectedTime && (
        <Box sx={{ mt: 1 }}>
          <Typography>선택한 날짜: {selectedDate.format('YYYY-MM-DD')}</Typography>
          <Typography>선택한 시간: {selectedTime}</Typography>
        </Box>
      )}
          </div>
          
        </div>
      )}
     
      {step === 4 && (
        <div className="date-form-buttons">
          <Button variant="contained" onClick={prevStep}>전단계</Button>
          <Button variant="contained" color="primary" onClick={handleNext}>다음단계</Button>
        </div>
      )}
    </div>
  );
};

export default DateSelection;
