import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Logout from './components/Logout';
import Basiccode1 from './components/Basiccode1';
import Basiccode2 from './components/Basiccode2';
import ServiceList from './components/ServiceList';
import EmployeeManagement from './components/EmployeeManagement';
import CustomerManagement2 from './components/Customer2';
import CustomerManagement from './components/Customer';
import Calendargoogle from './components/calendar';
import { login, logout,checkAuth } from './features/login/loginSlice';
import { useSelector, useDispatch } from 'react-redux';
import theme from './theme';

function App() {
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);



  return (
    <ThemeProvider theme={theme}>
    <Router>
    
      <Routes>
        <Route
          path="/ReservationSerarch"
          element={isAuthenticated ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/"
          element={<About />} />
     
        
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
